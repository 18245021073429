import { PortalModule } from '@angular/cdk/portal';
import { APP_BASE_HREF, CommonModule, DecimalPipe } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { GlobalErrorHandler } from '@app/app.errors';
import { AppRouterModule } from '@app/app.routes';
import { Forms } from '@app/forms';
import { Guards } from '@app/guards';
import { AppTranslatePipe } from '@app/pipes';
import { PlatformModule } from '@app/platform.module';
import { RouteReuseStrategy as CustomRouteReuseStrategy } from '@app/providers/route-reuse-strategy.provider';
import { CoreServices, OtherServices, TranslationStorageService } from '@app/services';
import { AppComponent, Views } from '@app/views';
import { AuthModule } from '@auth0/auth0-angular';
import { RoutingStateService } from '@common/services/routing-state.service';
import { environment as env, environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { HumiLogoComponent } from './components/humi-logo/humi-logo.component';
import { HttpModule } from './http.module';
import { InterceptorModule } from './interceptor.module';
import { EmployeeFormsModule } from './modules/forms/employees/employee-forms.module';
import { Report } from './modules/reporting/services/queries/report';
import { SpecialInformationBar } from './modules/special-information/components/special-information-bar/special-information-bar.component';
import { SpecialInformationModule } from './modules/special-information/special-information.module';
import { Auth0OptionalService } from './services/auth0optional.service';
import { FeatureService } from './services/feature.service';
import { RootTranslateModule } from './translate.module';
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        InterceptorModule,
        EmployeeFormsModule,
        AppRouterModule,
        PlatformModule,
        RootTranslateModule,
        PortalModule,
        HumiLogoComponent,
        SpecialInformationBar,
        SpecialInformationModule,
        AuthModule.forRoot({
            ...env.auth0,
            skipRedirectCallback: !window.location.href.startsWith(env.auth0?.authorizationParams.redirect_uri),
        }),
        ...environment.envModules,
    ],
    declarations: [...Views, ...Forms],
    providers: [
        // Base HREF - override browser history pushes
        { provide: APP_BASE_HREF, useValue: '/' },

        // Global Error Handler
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },

        // Sentry
        // from https://docs.sentry.io/platforms/javascript/guides/angular/
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line
            useFactory: () => () => {},
            multi: true,
            deps: [RoutingStateService],
        },
        {
            /**
             * In order to use this feature flag before signing in, this needs to be initialized in the factories.
             */
            provide: APP_INITIALIZER,
            useFactory: (auth0optional: Auth0OptionalService) => (): Promise<void> => auth0optional.setAuth0Optional(),
            multi: true,
            deps: [Auth0OptionalService, FeatureService],
        },
        // Setting DatePicker locale
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (translationStorageService: TranslationStorageService): string => {
                return translationStorageService.getLocale();
            },
            deps: [TranslationStorageService],
        },
        // Route Reuse Strategy
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        ...CoreServices,
        ...OtherServices,
        ...Guards,

        // Some Angular Pipes
        DecimalPipe,
        RoutingStateService,
        Report,
        AppTranslatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
