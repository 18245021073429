import { Component, computed, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { OnboardingStatus, OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import { SpecialInformationBarService } from '@app/modules/special-information/special-information-bar.service';
import { PlatformModule } from '@app/platform.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseInformationBar } from '../../base-information-type.component';
import { SelfServeTrialDaysRemaining } from '../trial-days-left.component';

/**
 * The quick start banner shown for self-serve clients to show how many steps through the quickstart process they have completed
 */
@Component({
    selector: 'special-info-quick-start',
    standalone: true,
    imports: [PlatformModule, TranslateModule, SelfServeTrialDaysRemaining],
    templateUrl: './quick-start-info-bar.template.html',
    styleUrls: ['./quick-start-info-bar.style.scss'],
})
export class SpecialInfoQuickStart extends BaseInformationBar {
    stepsCompleted = computed(() => this._onboardingStatus()?.stepsCompleted ?? 0);
    totalSteps = computed(() => this._onboardingStatus()?.totalSteps ?? 0);

    shouldDisplay: Signal<boolean> = computed(() => {
        const { isAdmin, selfServe } = this.displayConditions() || {};
        const { onboardingStatus, selfServeQuickstart } = selfServe || {};

        // if the onboarding status has not yet been propagated, show nothing
        if (!onboardingStatus) {
            return false;
        }

        if (!(isAdmin && !!selfServeQuickstart)) {
            return false;
        }

        // don't show for users who have completed selfServe
        return !selfServeQuickstart?.completedAt;
    });

    get trialExpiresAt(): Date | null | undefined {
        return this.displayConditions()?.selfServe?.selfServeQuickstart?.trialExpiresAt;
    }

    private _onboardingStatus: Signal<OnboardingStatus | null>;

    constructor(
        private onboardingStatusService: OnboardingStatusService,
        specialInformationBarService: SpecialInformationBarService
    ) {
        super(specialInformationBarService);
        this._onboardingStatus = toSignal(this.onboardingStatusService.onboardingStatus$, { requireSync: true });
    }
}
