import { HttpClient, HttpContext } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogoFull } from '@app/constants';
import { SKIP_AUTH0_TOKEN, SKIP_HUMI_TOKEN } from '@app/interceptors/context-tokens';
import { NotifyService } from '@app/services';
import { environment } from '@env/environment';

function errorIsExpiryError(err: any): boolean {
    if (!err || !err.error || !err.error.errors) {
        return false;
    }

    return err.error.errors[0] === 'Token has expired.';
}
@Component({
    templateUrl: 'verify-email.view.html',
    styleUrls: ['verify-email.style.scss'],
})
export class VerifyEmailView implements OnInit {
    isLoading = false;
    verified = false;
    tokenHasExpired = false;
    logoPath: string = LogoFull;
    private token: string;

    constructor(
        private route: ActivatedRoute,
        private notify: NotifyService,
        private http: HttpClient
    ) {
        this.token = this.route.snapshot.queryParams['token'];
    }

    /**
     * Navigate to the homepage in a new window
     */
    home(): void {
        window.open('https://www.humi.ca');
    }

    ngOnInit(): void {
        if (!this.token) {
            this.isLoading = false;
            return;
        }

        $('body').addClass('login');
        $('.ui.basic.login.segment').height($(window).height());

        this.isLoading = true;
        const context = new HttpContext().set(SKIP_HUMI_TOKEN, true).set(SKIP_AUTH0_TOKEN, true);
        this.http
            .get(environment.api + '/v2/accountManagement/verifyEmail?token=' + this.token, { context })
            .toPromise()
            .then(() => {
                this.verified = true;
                this.isLoading = false;
            })
            .catch((err) => {
                if (errorIsExpiryError(err)) {
                    this.tokenHasExpired = true;
                    this.isLoading = false;
                    return;
                }

                this.verified = false;
                this.isLoading = false;
                this.notify.error('verifyEmail.unableToVerifyEmail');
            });
    }

    viewAccount(): void {
        // Because this is an unauthenticated route we don't use the Angular router.
        // This ensures that if the user is not logged in, Auth0 will still redirect them to the correct location after login
        window.location.href = 'account/account';
    }
}
