import {
    ACCOUNT_ERROR_CODES,
    ACCOUNT_STATUS_CODES,
    EMAIL_ERROR_VALUES,
    PASSWORD_ERROR_CODES,
} from '@account/constants/error-codes';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppResources } from '@app/app.resources';
import { ErrorParser } from '@app/classes';
import { Logo } from '@app/constants';
import { FeatureFlag } from '@app/enums';
import { Images } from '@app/enums/images.enum';
import { JsonApiError } from '@app/errors/json-api.error';
import { AuthService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { TransifexToggleService } from '@app/services/language/transifex-toggle.service';
import { environment } from '@env/environment';
import { isEmpty } from 'lodash-es';

const MINIMUM_ATTEMPTS_REMAINING_ALLOWED = 2;
const ATTEMPTS_WARNING_THRESHOLD = 10;
enum FieldNames {
    EMAIL = 'email',
    PASSWORD = 'password',
}

@Component({
    templateUrl: './login.template.html',
    styleUrls: ['../common.style.scss', '../common.responsive.scss', './login.style.scss'],
})
export class LoginView implements OnInit, OnDestroy {
    readonly logoPath = Logo;
    readonly microsoftLogo = Images.MicrosoftTransparent;
    readonly googleLogo = Images.GoogleTransparent;
    readonly helpLink = AppResources.HelpCenter;
    readonly salesLink = AppResources.SalesPage;

    showPassword = false;
    apiVersion = '';
    buildStamp = '';
    attemptsRemaining = 0;
    showAttemptsWarning = false;
    standardErrorFeatureFlag = false;
    accountStatusError: { header: string; body: string } | null = null;

    email: FormControl<string | null> = new FormControl();
    password: FormControl<string | null> = new FormControl();

    private emailInput: HTMLInputElement;
    private passwordInput: HTMLInputElement;

    private get queryParamEmail(): string {
        return this.route.snapshot.queryParams['email'];
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private element: ElementRef,
        private meta: Meta,
        private featureService: FeatureService,
        private transifexDebug: TransifexToggleService
    ) {}

    async ngOnInit(): Promise<void> {
        this.setHtmlElements();
        this.standardErrorFeatureFlag = await this.featureService.has(FeatureFlag.standardErrorResponse);

        this.addMetaTag();
        fetch(environment.api + AppResources.Version)
            .then((res) => res.json())
            .then((res) => {
                this.buildStamp = res.meta.buildDate;
                this.apiVersion = res.meta.build;

                // Let angular set all the flags first that it needs
                setTimeout(() => {
                    this.prepareInput();
                });
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    ngOnDestroy(): void {
        this.removeMetaTag();
    }

    toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    googleOauth(event: MouseEvent): void {
        event.preventDefault();

        window.location.href = environment.api + '/v2/accountManagement/login/google';
        return;
    }

    microsoftOauth(event: MouseEvent): void {
        event.preventDefault();
        window.location.href = environment.api + '/v2/accountManagement/login/microsoft';
        return;
    }

    login(): void {
        $('.app-loader').show().addClass('active');
        this.authService
            .authenticateWithCredentials({ email: this.email.value, password: this.password.value })
            .then(() => {
                if (environment.name === 'local') {
                    this.transifexDebug.disableTransifex();
                }
                this.router.navigate(['/dashboard']);
            })
            .catch((err) => this.onLoginFailure(err));
    }

    closeWarning(): void {
        this.showAttemptsWarning = false;
    }

    private onLoginFailure(err: JsonApiError): void {
        $('.app-loader').hide().removeClass('active');

        /**
         * The bulk of this method is accounting for inconsistent error responses from the backend.
         * This spaghettini alfredo parses the affront to god that is our "error response" to give usable errors to the client.
         * If the error is some robotic nonsense like "data.attributes.doodoo" is required, then we just set it to the regular required
         * field.
         *
         * I don't know anymore, nothing makes sense. Blue is red, pain is pleasure.
         *
         * All code is equal, but some code is more equal than others.
         */
        this.accountStatusError = null;
        const errorMap = ErrorParser.parseAsMap(err.raw.error);
        if (this.standardErrorFeatureFlag) {
            const invalidResponse = ErrorParser.getInvalidResponseFields(err.raw.error);
            if (invalidResponse) {
                this.password.setErrors({ custom: { message: invalidResponse } });
                return;
            }
            const jsonErrors = err.raw.error.errors;
            jsonErrors.forEach((error: JsonApiError) => {
                /**
                 * This should be redesigned to always have the error in the same place on the page.
                 * Normally, it should just be an ErrorParser.parseJsonApiError(error).
                 * But we have to show errors in different places based on where they belong (email/password/custom).
                 */
                const hasSource = Boolean(error.source?.pointer);
                if (this.isEmailError(error, hasSource)) {
                    this.setFieldErrors(FieldNames.EMAIL, hasSource, error);
                }
                if (this.isPasswordError(error, hasSource)) {
                    this.setFieldErrors(FieldNames.PASSWORD, hasSource, error);
                    const errorHasRemainingAttempts = Boolean(error.meta && !isEmpty(error.meta) && error.meta.flags);
                    if (errorHasRemainingAttempts) {
                        this.attemptsRemaining = error.meta.flags.remainingAttempts;
                        this.showAttemptsWarning = this.attemptsRemaining <= ATTEMPTS_WARNING_THRESHOLD;
                        if (this.attemptsRemaining < MINIMUM_ATTEMPTS_REMAINING_ALLOWED) {
                            this.router.navigate(['/reset']);
                        }
                    }
                }
                if (this.isAccountStatusError(error)) {
                    this.accountStatusError = {
                        header: error.title,
                        body: `${error.detail} - ${error.code}`,
                    };
                    return;
                }
                if (this.isAccountError(error)) {
                    this.email.setErrors({ custom: { message: `${error.detail} - ${error.code}` } });
                }
            });
            return;
        }
        const isErrorObject = errorMap !== null && typeof errorMap === 'object';

        // Special statuses such as "locked", "inactive", "deactivated"
        this.accountStatusError = this.getAccountStatusError(err);
        if (this.accountStatusError) {
            return;
        }

        const v1AccountError = this.getV1AccountError(err);
        const authorizationError = this.getAuthorizationError(err);
        const accountError = this.getAccountError(err);
        const passwordError = this.getPasswordError(err);

        if (v1AccountError) {
            window.location.href = v1AccountError;
            return;
        }

        if (accountError) {
            this.email.setErrors({ custom: { message: accountError } });
        } else if (!passwordError && isErrorObject && FieldNames.EMAIL in errorMap) {
            if (errorMap.email.indexOf('required') > -1) {
                this.email.setErrors({ required: true });
            } else {
                this.email.setErrors({ custom: { message: errorMap.email } });
            }
        } else {
            this.email.setErrors(null);
        }

        if (passwordError) {
            this.password.setErrors({ custom: { message: passwordError } });
        } else if (!accountError && isErrorObject && FieldNames.PASSWORD in errorMap) {
            if (errorMap.password.indexOf('required') > -1) {
                this.password.setErrors({ required: true });
            } else {
                this.password.setErrors({ custom: { message: errorMap.password } });
            }
        } else {
            this.password.setErrors(null);
        }

        if (!accountError && !passwordError && authorizationError) {
            this.password.setErrors({ custom: { message: authorizationError } });
        }

        const remainingAttempts = this.getRemainingAttempts(err);
        if (remainingAttempts) {
            this.attemptsRemaining = (err as any).raw.error.flags.remainingAttempts;
            this.showAttemptsWarning = this.attemptsRemaining <= ATTEMPTS_WARNING_THRESHOLD;
            if (this.attemptsRemaining < MINIMUM_ATTEMPTS_REMAINING_ALLOWED) {
                this.router.navigate(['/reset']);
            }
        }
    }

    private isPasswordError(error: JsonApiError, source: boolean): boolean | undefined {
        return (
            PASSWORD_ERROR_CODES.includes(error.code) ||
            (source && error.source?.pointer?.includes(FieldNames.PASSWORD))
        );
    }

    private isAccountStatusError(error: JsonApiError): boolean {
        return ACCOUNT_STATUS_CODES.includes(error.code);
    }

    private isEmailError(error: JsonApiError, source: boolean): boolean {
        return (
            source &&
            error.source?.pointer !== undefined &&
            Object.values(EMAIL_ERROR_VALUES).includes(error.source.pointer)
        );
    }

    private isAccountError(error: JsonApiError): boolean {
        return ACCOUNT_ERROR_CODES.includes(error.code);
    }

    private setFieldErrors(
        fieldName: FieldNames.EMAIL | FieldNames.PASSWORD,
        hasSource: boolean,
        error: JsonApiError
    ): void {
        this[fieldName].setErrors(
            hasSource && error.detail.includes('required')
                ? { required: true }
                : { custom: { message: `${error.detail} - ${error.code}` } }
        );
    }

    private getAccountStatusError(err: any): { header: string; body: string } | null {
        const errors = err.raw.error;
        if ('accountStatus' in errors && 'statusMessage' in errors) {
            return { header: errors.accountStatus, body: errors.statusMessage };
        }

        return null;
    }

    private getV1AccountError(err: any): string | null {
        return 'redirect' in err.raw.error ? err.raw.error.redirect : null;
    }

    private getAccountError(err: any): string | null {
        return 'account' in err.raw.error ? err.raw.error.account : null;
    }

    private getPasswordError(err: any): string | null {
        return 'password' in err.raw.error ? err.raw.error.password : null;
    }

    private getAuthorizationError(err: any): string | null {
        return 'authorization' in err.raw.error ? err.raw.error.authorization : null;
    }

    private getRemainingAttempts(err: any): number | null {
        return 'flags' in err.raw.error && 'remainingAttempts' in err.raw.error.flags
            ? err.raw.error.flags.remainingAttempts
            : null;
    }

    private prepareInput(): void {
        if (this.queryParamEmail) {
            this.email.setValue(this.queryParamEmail);
            this.passwordInput.focus();
            return;
        }

        this.emailInput.focus();
    }

    private addMetaTag(): void {
        this.meta.addTag({
            name: 'description',
            content:
                '“Log in to the Humi platform here. You’ll be able to access all your HR, Payroll, and Benefits needs, and more.',
        });
    }

    private removeMetaTag(): void {
        this.meta.removeTag('name=description');
    }

    private setHtmlElements(): void {
        this.emailInput = this.element.nativeElement.querySelector('input[name="email"]');
        this.passwordInput = this.element.nativeElement.querySelector('input[name="password"]');
    }
}
