import { Component, Input } from '@angular/core';
import { LogoFull } from '@app/constants';

export type Sizes = 'tiny' | 'small' | 'medium' | 'large' | 'huge';

/**
 * HumiLogoComponent is a component that shows the Humi logo.
 */
@Component({
    imports: [],
    selector: 'app-humi-logo',
    standalone: true,
    styleUrls: ['./humi-logo.component.scss'],
    templateUrl: './humi-logo.component.html',
})
export class HumiLogoComponent {
    @Input() size: Sizes = 'medium';

    readonly logoPath: string = LogoFull;
}
