import { Component, ElementRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { LogoFull } from '@app/constants/logo-paths';
import { AnalyticEvents } from '@app/enums';
import { AnalyticService, NotifyService, OfferLetterSigningService } from '@app/services';
import { HelloSignEmbedService } from '@app/services/hello-sign-embed.service';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
    templateUrl: './offer-letter-sign.template.html',
    styleUrls: ['./offer-letter-sign.style.scss'],
    providers: [HelloSignEmbedService],
})
export class OfferLetterSignView {
    @ViewChild('helloSignContainer', { static: true }) helloSignContainer!: ElementRef<HTMLDivElement>;

    logoPath: string = LogoFull;
    isLoading = false;
    signingUrl?: string;
    isSigned = false;

    constructor(
        private route: ActivatedRoute,
        private helloSign: HelloSignEmbedService,
        private notify: NotifyService,
        private offerLetterSigningService: OfferLetterSigningService,
        private analyticsService: AnalyticService
    ) {
        this.route.params
            .pipe(
                takeUntilDestroyed(),
                map((params) => params['offerLetterUuid']),
                filter((offerLetterUuid: string) => !isNil(offerLetterUuid)),
                switchMap((offerLetterUuid) => this.getSigningUrl(offerLetterUuid)),
                tap((signingUrl) => (this.signingUrl = signingUrl))
            )
            .subscribe((signingUrl) => this.openHelloSignContainer(signingUrl));
    }

    private getSigningUrl(offerLetterUuid: string): Observable<string | undefined> {
        this.isLoading = true;
        return this.offerLetterSigningService.fetchSigningUrl(offerLetterUuid).pipe(
            tap(() => this.analyticsService.trackEvent(AnalyticEvents.OfferLetterCandidateReviewOfferLetter)),
            catchError((err) => {
                this.notify.error(err);
                return of('');
            }),
            finalize(() => (this.isLoading = false))
        );
    }

    private async openHelloSignContainer(signingUrl: string | undefined): Promise<void> {
        try {
            if (!signingUrl) {
                throw new Error();
            }
            this.helloSign.onSignEventOnce(async () => {
                this.isSigned = true;

                this.analyticsService.trackEvent(AnalyticEvents.OfferLetterCandidateSignedOfferLetter);
            });

            await this.helloSign.open(signingUrl, this.helloSignContainer, true);
        } catch (err) {
            this.notify.error(err.message || 'Could not open the hello sign url.');
        }
    }
}
