import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { TokenService } from '@app/services';

/**
 * This param key is used in emails sent out from humi, do not modify this value unless you are certain that all links have been updated.
 */
const URL_PARAM_KEY = 'auth_co';

/**
 * This guard is run before any others in the app when navigating to an authenticated route.
 * It is meant to allow users to be linked to our app via URLs that can also specify which company they're viewing
 *
 * ie. https://hr.humi.ca/profile/paystubs?auth_co=1
 *
 * If the user has multiple companies associated with their account, this assures they view paystubs for company 1 when they get their email.
 * ! This guard has to be run first because we need to set the company BEFORE hydration to ensure all back-end calls use the correct token
 */
const canMatch: CanMatchFn = (_route: Route, segments: UrlSegment[]): boolean | UrlTree => {
    try {
        const router = inject(Router);
        const tokenService = inject(TokenService);

        const queryParams = router.getCurrentNavigation()?.initialUrl.queryParams;
        if (queryParams) {
            const companyId: string = queryParams[URL_PARAM_KEY];

            if (companyId) {
                // We set the company ID in LocalStorage, this is the same method that is used when switching companies/roles via the topbar
                // It ensures that when we call useInitialRole, we will select the correct company
                tokenService.setCompanyToken(Number.parseInt(companyId));

                // Remove the param from the URL now that it's been persisted in LocalStorage
                delete queryParams[URL_PARAM_KEY];
                return router.createUrlTree(segments, {
                    queryParams,
                });
            }
        }
    } catch (e) {
        // We never want query param parsing to cause an exception for users, if for some reason we run into an error, just continue as per usual
    }
    return true;
};

export const MultiCompanyRedirectGuard = {
    canMatch,
};
