<div class="wrapper">
    <div class="ui segment" [class.loading]="isLoading">
        <img class="logo" [src]="logoPath" />

        <ui-flag-deprecated
            *ngIf="sent"
            icon="WhiteCheckMark"
            header="{{ 'passwordReset.passwordResetLinkSent' | translate }}"
        >
            <p class="flag-content">{{ 'passwordReset.clickLinkToCreatePassword' | translate }}</p>
        </ui-flag-deprecated>

        <div *ngIf="!sent" class="body">
            <h5>{{ 'passwordReset.howCanWeHelp' | translate }}</h5>
            <mat-radio-group aria-label="Password Reset Reason" name="selection" [(ngModel)]="selection">
                <mat-radio-button value="forgot">{{ 'passwordReset.forgotMyPassword' | translate }}</mat-radio-button>
                <br />
                <mat-radio-button value="different">{{ 'passwordReset.differentIssue' | translate }}</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="selection === 'forgot'; else different" class="password-reset">
                <h3 class="header">{{ 'passwordReset.passwordReset' | translate }}</h3>
                <p>{{ 'passwordReset.enterEmailAddress' | translate }}</p>
                <form #resetForm="ngForm" (ngSubmit)="reset(resetForm)">
                    <mat-form-field>
                        <mat-label>{{ 'passwordReset.email' | translate }}</mat-label>
                        <input
                            matInput
                            type="email"
                            required
                            name="email"
                            email
                            #emailControl="ngModel"
                            [(ngModel)]="email"
                        />
                        <mat-error *ngIf="emailControl.invalid">{{ emailControl.errors | formErrors }}</mat-error>
                    </mat-form-field>
                    <ui-button submit class="margin-bottom margin-top-1" type="primary">{{
                        'passwordReset.sendResetLink' | translate
                    }}</ui-button>
                </form>
            </div>

            <ng-template #different>
                <div class="contact">
                    <h3 class="header">{{ 'passwordReset.contactSupport' | translate }}</h3>
                    <p>{{ 'passwordReset.submitSupportTicket' | translate }}</p>
                    <ui-button class="margin-bottom" type="primary" [url]="supportLink" externalLink="true"
                        >{{ 'passwordReset.openSupportTicket' | translate }}
                    </ui-button>
                </div>
            </ng-template>
            <ui-button type="link" url="/login">{{ 'passwordReset.backToSignIn' | translate }}</ui-button>
        </div>
    </div>
</div>
